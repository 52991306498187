class UrlConfig {
  getImeiNumbers() {
    return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetRMUImei";
  }

  getLiveDataURL() {
    return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetRMUDetails";
  }

  getDeviceStatusURL() {
    return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetDeviceStats";
  }

  getLatLongURL() {
    return "http://ordertracker-env.eba-erviupgq.us-east-1.elasticbeanstalk.com/api/RMU/GetDevicesLatlong";
  }
}

export default new UrlConfig();
