import {
  GET_RMU_REPORT_LOADING,
  GET_RMU_REPORT_SUCCESS,
  GET_RMU_REPORT_ERROR,
  GET_GRAPH_DATA_LOADING,
  GET_GRAPH_DATA_SUCCESS,
  GET_GRAPH_DATA_ERROR,
  GET_ALERT_VIEW_LOADING,
  GET_ALERT_VIEW_SUCCESS,
  GET_ALERT_VIEW_ERROR,
  REMOVE_RMU_REPORT,
  SET_GRAPH_DATA,
  STORE_IMEI_GLOBAL,
  CLEAR_IMEI_GLOBAL,
  REMOVE_REPORT_DATA,
  CLEAR_GRAPH_DATA,
  GET_AGENCY_LOADING,
  GET_AGENCY_SUCCESS,
  GET_AGENCY_ERROR,
} from "../../actions/RMUReport/ReportActions";

const initialState = {
  isReportLoading: false,
  reportData: [],
  graphDataList: [],
  alertDataList: [],
  graphData: [],
  globalIMEI: null,
  AgencyUserData: [],
  AgencyLoading: false,
  AgencyUserDataError: false,
};

const reportReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_RMU_REPORT_LOADING: {
      return {
        ...state,
        isReportLoading: true,
      };
    }

    case GET_RMU_REPORT_SUCCESS: {
      return {
        ...state,
        reportData: action.data,
        isReportLoading: false,
      };
    }

    case GET_RMU_REPORT_ERROR: {
      return {
        ...state,
        isReportLoading: false,
      };
    }

    case GET_AGENCY_LOADING: {
      return {
        ...state,
        AgencyLoading: true,
      };
    }

    case GET_AGENCY_SUCCESS: {
      return {
        ...state,
        AgencyUserData: action.data,
        AgencyLoading: false,
      };
    }

    case GET_AGENCY_ERROR: {
      return {
        ...state,
        GET_AGENCY_ERROR: true,
        AgencyLoading: false,
      };
    }

    case CLEAR_GRAPH_DATA: {
      return {
        ...state,
        graphDataList: action.data,
      };
    }

    //Get graph data
    case GET_GRAPH_DATA_LOADING: {
      return {
        ...state,
        isGraphLoading: true,
      };
    }

    case GET_GRAPH_DATA_SUCCESS: {
      return {
        ...state,
        graphDataList: action.data,
        isGraphLoading: false,
      };
    }

    case GET_GRAPH_DATA_ERROR: {
      return {
        ...state,
        isGraphLoading: false,
      };
    }

    //Get Alert view data
    case GET_ALERT_VIEW_LOADING: {
      return {
        ...state,
        isReportLoading: true,
      };
    }

    case GET_ALERT_VIEW_SUCCESS: {
      return {
        ...state,
        alertDataList: action.data,
        isReportLoading: false,
      };
    }

    case GET_ALERT_VIEW_ERROR: {
      return {
        ...state,
        isReportLoading: false,
      };
    }

    case REMOVE_RMU_REPORT: {
      return {
        ...state,
        alertDataList: [],
        isReportLoading: false,
      };
    }

    case SET_GRAPH_DATA: {
      return {
        graphData: action.data,
      };
    }

    case STORE_IMEI_GLOBAL: {
      return {
        globalIMEI: action.data,
      };
    }

    case CLEAR_IMEI_GLOBAL: {
      return {
        globalIMEI: null,
      };
    }

    case REMOVE_REPORT_DATA: {
      return {
        graphDataList: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default reportReducer;
