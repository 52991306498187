import history from "history.js";
// import jwtAuthService from "../../services/jwtAuthService";
import { get, post } from "../baseApi";
import { error, success } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const ADD_USER_LOADING = "ADD_USER_LOADING";

export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

export const SUB_USER_LOADING = "SUB_USER_LOADING";
export const SUB_USER_SUCCESS = "SUB_USER_SUCCESS";
export const SUB_USER_ERROR = "SUB_USER_ERROR";

export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const SUB_USER_FEATURE_ERROR = "SUB_USER_FEATURE_ERROR";
export const SUB_USER_FEATURE_SUCCESS = "SUB_USER_FEATURE_SUCCESS";
export const SUB_USER_FEATURE_LOADING = "SUB_USER_FEATURE_LOADING";

export function setUserData(user) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    });
  };
}

export function logoutUser() {
  return (dispatch) => {
    //jwtAuthService.logout();
    sessionStorage.removeItem("auth_user");
    //need to set session as null, refer jwtAuthService.js line-55
    sessionStorage.removeItem("user_name");
    sessionStorage.removeItem("user_id");
    sessionStorage.removeItem("jwt_token");
    sessionStorage.removeItem("UserGroupId");

    history.push({
      pathname: "/session/signin",
    });

    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}

export const createUser = (payload) => async (dispatch) => {
  let url = UrlConfig.addUser();

  dispatch({
    type: ADD_USER_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    success("User added successfully...");

    dispatch({
      type: CREATE_USER_SUCCESS,
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while adding user..");
    }
    dispatch({
      type: CREATE_USER_ERROR,
      //payload: res.isAxiosError
    });
    return false;
  }
};

export const updateUser = (id, payload) => async (dispatch) => {
  let url = UrlConfig.updateUser();
  url = `${url}${id}`;

  dispatch({
    type: UPDATE_USER_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    //res && res.data
    success("User updated successfully...");
    return dispatch({
      type: UPDATE_USER_SUCCESS,
      //data: user//res.data
    });
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while updating user...");
    }
    return dispatch({
      type: UPDATE_USER_ERROR,
      //payload: res.isAxiosError
    });
  }
};

export const getSubUsers = (id) => async (dispatch) => {
  let url = UrlConfig.getSubUsers();
  url = `${url}${id}`;

  dispatch({
    type: SUB_USER_LOADING,
  });

  let header = authHeaderWithToken();

  let res = await get(url, header);
  if (res && res.status === 200) {
    //res && res.data

    return dispatch({
      type: SUB_USER_SUCCESS,
      data: res.data, //res.data
    });
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching user...");
    }
    return dispatch({
      type: SUB_USER_ERROR,
      //payload: res.isAxiosError
    });
  }
};

export const getUserSubscribedFeature =
  (userId, groupId) => async (dispatch) => {
    let url = UrlConfig.getSubscribedFeature();
    url = `${url}${userId}/${groupId}`;

    dispatch({
      type: SUB_USER_FEATURE_LOADING,
    });
    let header = authHeaderWithToken();

    let res = await get(url, header);
    if (res && res.status === 200) {
      //res && res.data

      dispatch({
        type: SUB_USER_FEATURE_SUCCESS,
      });
      return res.data;
    } else if (res && res.isAxiosError) {
      if (res.response && res.response.data && res.response.data.message) {
        error(res.response.data.message);
      } else {
        error("Error while fetching user...");
      }
      dispatch({
        type: SUB_USER_FEATURE_ERROR,
        //payload: res.isAxiosError
      });
      return null;
    }
  };
