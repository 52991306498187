import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";
import MatxVerticalNavExpansionPanel from "./MatxVerticalNavExpansionPanel";
import { withStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import _ from "lodash";
import AnimationIcons from "../../../../src/app/views/material-kit/AnimationsIcons";

const styles = (theme) => ({
  expandIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(90deg)",
  },
  collapseIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)",
  },
});

const MatxVerticalNav = (props) => {
  const navigations = useSelector(({ navigations }) => navigations);
  console.log(navigations);

  // const getFilteredNav = (feature, children) => {
  //   let arr = [];
  //   children.map(item => {
  //     let res = '';
  //     if(item.features === ''){
  //       res = item;
  //     }
  //     else{
  //       if(feature.includes(item.features)){
  //         res = item;
  //       }
  //     }

  //     if(res)
  //       arr.push(res);
  //   });
  //   return arr;
  // }

  const getFilteredNav = (feature, children) => {
    let arr = [];
    children.map((item) => {
      let res = "";
      if (feature.includes(item.features)) {
        res = item;
      }
      if (res) arr.push(res);
    });
    return arr;
  };

  const renderLevels = (data) => {
    return data.map((item, index) => {
      if (item.children) {
        //filter array based on features
        let features = props.features;
        let filteredChildren = getFilteredNav(features, item.children);
        return (
          <MatxVerticalNavExpansionPanel item={item} key={index}>
            {renderLevels(filteredChildren)}
          </MatxVerticalNavExpansionPanel>
        );
      } else if (item.type === "extLink") {
        return (
          <a
            key={index}
            href={item.path}
            className="nav-item"
            rel="noopener noreferrer"
            target="_blank"
          >
            <TouchRipple key={item.name} name="child" className="w-full">
              {(() => {
                if (item.icon) {
                  return (
                    <Icon className="item-icon align-middle">{item.icon}</Icon>
                  );
                } else {
                  return (
                    <span className="item-icon icon-text">{item.iconText}</span>
                  );
                }
              })()}
              <span className="align-middle item-text">{item.name}</span>
              <div className="mx-auto"></div>
              {item.badge && (
                <div className={`badge bg-${item.badge.color}`}>
                  {item.badge.value}
                </div>
              )}
            </TouchRipple>
          </a>
        );
      } else {
        return (
          <NavLink key={index} to={item.path} className="nav-item">
            <TouchRipple key={item.name} name="child" className="w-full">
              {(() => {
                if (item.icon) {
                  return item.isAnimate ? (
                    <AnimationIcons iconType={item.type} />
                  ) : (
                    <Icon className="item-icon align-middle">{item.icon}</Icon>
                  );
                } else {
                  return (
                    <span className="item-icon icon-text">{item.iconText}</span>
                  );
                }
              })()}
              <span className="align-middle item-text">{item.name}</span>
              <div className="mx-auto"></div>
              {item.badge && (
                <div className={`badge bg-${item.badge.color}`}>
                  {item.badge.value}
                </div>
              )}
            </TouchRipple>
          </NavLink>
        );
      }
    });
  };

  return <div className="navigation">{renderLevels(navigations)}</div>;
};

const mapStateToProps = (state) => ({
  //handleLogin: PropTypes.func.isRequired,
  features: state.user.userDet.features,
});

// export default withStyles(styles)(MatxVerticalNav);
export default withStyles(styles)(connect(mapStateToProps)(MatxVerticalNav));
