import Axios from "axios";
var Qs = require("qs");

export const get = (url, headers = {}) => {
  return Axios.get(url, { headers: headers }).then(
    (resp) => {
      return resp;
    },
    (err) => {
      console.error("Error from GET call of ", url);
    }
  );
};

// export const getWithParams = (url, params, headers={}) => {
//   //let param = {reportid: "43cd419b-a6be-4055-9dc0-e1c575d519ce"}
//   return Axios.get(url,
//     {params: params},
//     {paramsSerializer: function(params) {
//       return Qs.stringify(params, {arrayFormat: 'brackets'})
//     }},
//     {headers: headers}).then(
//     resp => {
//       return resp;
//     },
//     err => {
//       console.error("Error from GET call of ", url);
//     }
//   );
// };

export const post = (url, data, headers = {}) => {
  return Axios.post(url, data, { headers: headers }).then(
    (resp) => {
      return resp;
    },
    (err) => {
      console.error("Error from POST call of ", url);
      return err;
    }
  );
};
