import axios from "axios";
import { error, success, warning } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";
import { get, post, getWithParams } from "../baseApi";

export const RMU_DASHBOARD_LOADING = "RMU_DASHBOARD_LOADING";
export const RMU_DASHBOARD_SUCCESS = "RMU_DASHBOARD_SUCCESS";
export const RMU_DASHBOARD_ERROR = "RMU_DASHBOARD_ERROR";

export const DASHBOARD_LOADING = "DASHBOARD_LOADING";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";

export const LOCATIONS_LOADING = "LOCATIONS_LOADING";
export const LOCATIONS_SUCCESS = "LOCATIONS_SUCCESS";
export const LOCATIONS_ERROR = "LOCATIONS_ERROR";

export const getInitialData = () => async (dispatch) => {
  let getImeiNumbers = UrlConfig.getImeiNumbers();
  dispatch({
    type: RMU_DASHBOARD_LOADING,
  });

  let header = authHeaderWithToken();
  let res = {};
  axios
    .all([axios.get(getImeiNumbers, { headers: header })])
    .then((resp) => {
      console.log("RMU Initial: ", resp[0].data);
      res = {
        ...res,
        RmuImeiNumbers: resp[0].data,
      };
      dispatch({
        type: RMU_DASHBOARD_SUCCESS,
        data: res,
      });
    })
    .catch((err) => {
      console.error("Error from POST call of ");
      dispatch({
        type: RMU_DASHBOARD_ERROR,
      });
    });
};

export const getDashboardData = (userid) => async (dispatch) => {
  let url = UrlConfig.getDeviceStatusURL();
  let getDeviceStatusURL = `${url}/${userid}`;

  dispatch({
    type: DASHBOARD_LOADING,
  });

  let header = authHeaderWithToken();
  let res = {};

  axios
    .all([axios.get(getDeviceStatusURL, { headers: header })])
    .then((resp) => {
      console.log("device status: ", resp[0].data);
      res = {
        ...res,
        deviceStatus: resp[0].data,
      };

      dispatch({
        type: DASHBOARD_SUCCESS,
        data: res,
      });
    })
    .catch((err) => {
      error("Error while fetching data...");
      dispatch({
        type: DASHBOARD_ERROR,
      });
    });
};

export const getLocationsList = (userid) => async (dispatch) => {
  let url = UrlConfig.getLatLongURL();
  url = `${url}/${userid}`;
  dispatch({
    type: LOCATIONS_LOADING,
  });

  let header = authHeaderWithToken();

  let res = await get(url, header);
  if (res && res.status === 200) {
    return dispatch({
      type: LOCATIONS_SUCCESS,
      data: res.data,
    });
  } else if (res && res.status === 204) {
    warning("Content not available.");
    return dispatch({
      type: LOCATIONS_SUCCESS,
      data: res.data,
    });
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching report...");
    }
    return dispatch({
      type: LOCATIONS_ERROR,
    });
  }
};
