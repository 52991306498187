// import history from "history.js";
import axios from "axios";
import { get, post, getWithParams } from "../baseApi";
import { error, success, warning } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken, authHeaderWithOutToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const GET_RMU_REPORT_LOADING = "GET_RMU_REPORT_LOADING";
export const GET_RMU_REPORT_SUCCESS = "GET_RMU_REPORT_SUCCESS";
export const GET_RMU_REPORT_ERROR = "GET_RMU_REPORT_ERROR  ";

export const GET_GRAPH_DATA_LOADING = "GET_GRAPH_DATA_LOADING";
export const GET_GRAPH_DATA_SUCCESS = "GET_GRAPH_DATA_SUCCESS";
export const GET_GRAPH_DATA_ERROR = "GET_GRAPH_DATA_ERROR  ";

export const GET_ALERT_VIEW_LOADING = "GET_ALERT_VIEW_LOADING";
export const GET_ALERT_VIEW_SUCCESS = "GET_ALERT_VIEW_SUCCESS";
export const GET_ALERT_VIEW_ERROR = "GET_ALERT_VIEW_ERROR  ";

export const REMOVE_RMU_REPORT = "REMOVE_RMU_REPORT";

export const SET_GRAPH_DATA = "SET_GRAPH_DATA";

export const STORE_IMEI_GLOBAL = "STORE_IMEI_GLOBAL";
export const CLEAR_IMEI_GLOBAL = "CLEAR_IMEI_GLOBAL";

export const REMOVE_REPORT_DATA = "REMOVE_REPORT_DATA";

export const CLEAR_GRAPH_DATA = "CLEAR_GRAPH_DATA";

export const GET_AGENCY_LOADING = "GET_AGENCY_LOADING";
export const GET_AGENCY_SUCCESS = "GET_AGENCY_SUCCESS";
export const GET_AGENCY_ERROR = "GET_AGENCY_ERROR  ";

export const ClearGraphData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GRAPH_DATA,
    data: [],
  });
};

export const removeReportData =
  (imei, startDate, endDate) => async (dispatch) => {
    dispatch({
      type: REMOVE_REPORT_DATA,
      data: null,
    });
  };

export const getRMUReportList = (imei, st, et) => async (dispatch) => {
  let url = UrlConfig.getReportURL();
  // url = `${url}${'/'+imei+'/'+st+'/'+et}`;
  url =
    st && et
      ? `${url}${"/" + imei + "/" + st + "/" + et}`
      : `${url}${"/" + imei}`;

  dispatch({
    type: GET_RMU_REPORT_LOADING,
  });

  let header = authHeaderWithToken();
  //let params = {reportid: id };

  let res = await get(url, header);
  if (res && res.status === 200) {
    //res && res.data

    return dispatch({
      type: GET_RMU_REPORT_SUCCESS,
      data: res.data, //res.data
    });
  } else if (res && res.status === 204) {
    warning("Content not available.");
    return dispatch({
      type: GET_RMU_REPORT_SUCCESS,
      data: res.data,
    });
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching report...");
    }
    return dispatch({
      type: GET_RMU_REPORT_ERROR,
      //payload: res.isAxiosError
    });
  }
};

function getTodaysDate() {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
let ParamFields = [{ name: "motorpower" }, { name: "pvOrDCVoltage" }];

export const getGraphDataList =
  (imei, startDate, endDate) => async (dispatch) => {
    let url = UrlConfig.getGraphDataURL();
    let _imei = imei.trim();
    if (startDate && endDate) {
      url = `${url}/${_imei}/${startDate}/${endDate}`;
    } else {
      const dt = getTodaysDate();
      url = `${url}/${_imei}/${dt}/${dt}`;
    }

    dispatch({
      type: GET_GRAPH_DATA_LOADING,
    });

    let header = authHeaderWithToken();

    let res = await get(url, header);
    if (res && res.status === 200) {
      let genData = generateGraphData1(res.data);
      return dispatch({
        type: GET_GRAPH_DATA_SUCCESS,
        data: genData, //res.data
      });
    } else if (res && res.status === 204) {
      warning("Content not available.");
      return dispatch({
        type: GET_GRAPH_DATA_SUCCESS,
        data: [],
      });
    } else if (res && res.isAxiosError) {
      if (res.response && res.response.data && res.response.data.message) {
        error(res.response.data.message);
      } else {
        error("Error while fetching report...");
      }
      return dispatch({
        type: GET_GRAPH_DATA_ERROR,
        //payload: res.isAxiosError
      });
    }
  };

export const removeRMUReport = () => (dispatch) => {
  return dispatch({
    type: REMOVE_RMU_REPORT,
  });
};

export const GetDeviceAlertView = (userid) => async (dispatch) => {
  let url = UrlConfig.getAlertViewURL();
  url = `${url}/${userid}`;
  dispatch({
    type: GET_ALERT_VIEW_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await get(url, header);
  if (res && res.status === 200) {
    return dispatch({
      type: GET_ALERT_VIEW_SUCCESS,
      data: res.data, //res.data
    });
  } else if (res && res.status === 204) {
    warning("Content not available.");
    return dispatch({
      type: GET_ALERT_VIEW_SUCCESS,
      data: res.data,
    });
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching report...");
    }
    return dispatch({
      type: GET_ALERT_VIEW_ERROR,
    });
  }
};

export const GetAgencyList = () => async (dispatch) => {
  let url = UrlConfig.getAgencyURL();
  dispatch({
    type: GET_AGENCY_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await get(url, header);
  if (res && res.status === 200) {
    return dispatch({
      type: GET_AGENCY_SUCCESS,
      data: res.data, //res.data
    });
  } else if (res && res.status === 204) {
    warning("Content not available.");
    return dispatch({
      type: GET_AGENCY_SUCCESS,
      data: res.data,
    });
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching report...");
    }
    return dispatch({
      type: GET_AGENCY_ERROR,
    });
  }
};

export const setGraphDataGlobally = (data) => (dispatch) => {
  dispatch({
    type: SET_GRAPH_DATA,
    data,
  });
};

export const storeIMEIGlobally = (imei) => (dispatch) => {
  dispatch({
    type: STORE_IMEI_GLOBAL,
    data: imei,
  });
};

export const clearGlobalIMEI = () => (dispatch) => {
  dispatch({
    type: CLEAR_IMEI_GLOBAL,
  });
};

let dataObject = {
  motorFrequency: true,
  pvCurrent: true,
  motorRMSCurrent: true,
  motorVoltageRMS: true,
  motorpower: true,
  pvOrDCVoltage: true,
  motorEnergyinkWH: true,
  deviceTemperature: true,
  motorPowerFactor: true,
  flowRate: true,
};

const generateGraphData1 = (data) => {
  let graphDataSet = [];

  for (var i = 0; i < data.length; i++) {
    graphDataSet.push({
      name: data[i].date,
      motorFrequency:
        data[i].motorFrequency != null ? parseFloat(data[i].motorFrequency) : 0,
      pvCurrent: data[i].pvCurrent != null ? parseFloat(data[i].pvCurrent) : 0,
      motorRMSCurrent:
        data[i].motorRMSCurrent != null
          ? parseFloat(data[i].motorRMSCurrent)
          : 0,
      motorVoltageRMS:
        data[i].motorVoltageRMS != null
          ? parseFloat(data[i].motorVoltageRMS)
          : 0,
      motorpower:
        data[i].motorpower != null ? parseFloat(data[i].motorpower) : 0,
      pvOrDCVoltage:
        data[i].pvOrDCVoltage != null ? parseFloat(data[i].pvOrDCVoltage) : 0,
      motorEnergyinkWH:
        data[i].motorEnergyinkWH != null
          ? parseFloat(data[i].motorEnergyinkWH)
          : 0,
      deviceTemperature:
        data[i].deviceTemperature != null
          ? parseFloat(data[i].deviceTemperature)
          : 0,
      motorPowerFactor:
        data[i].motorPowerFactor != null
          ? parseFloat(data[i].motorPowerFactor)
          : 0,
      flowRate: data[i].flowRate != null ? parseFloat(data[i].flowRate) : 0,
    });
  }
  return graphDataSet;
};

// const generateGraphData = (data) => {
//   let graphList = [];

//   if (dataObject.motorpower) {
//     const mp = [];
//     for (let i = 0; i < data.length; i++) {
//       mp.push(data[i].motorpower);
//     }
//     let name = "Motor Power";
//     const newRow = {
//       data: mp,
//       type: "line",
//       stack: "RMU Parameter graph1",
//       name: name,
//       smooth: true,
//       symbolSize: 6,
//       lineStyle: { width: 2 },
//     };
//     graphList.push(newRow);
//   }
//   if (dataObject.pvOrDCVoltage) {
//     const pvdc = [];
//     for (let i = 0; i < data.length; i++) {
//       if (data[i].pvOrDCVoltage === null) {
//         pvdc.push("0.00");
//       } else {
//         pvdc.push(data[i].pvOrDCVoltage);
//       }
//     }
//     let name = "PV DC Voltage";
//     const newRow = {
//       data: pvdc,
//       type: "line",
//       stack: "RMU Parameter graph2",
//       name: name,
//       smooth: true,
//       symbolSize: 6,
//       lineStyle: { width: 2 },
//     };
//     graphList.push(newRow);
//   }
//   if (dataObject.pvCurrent) {
//     const pvc = [];
//     for (let i = 0; i < data.length; i++) {
//       if (data[i].pvCurrent === null) {
//         pvc.push("0.00");
//       } else {
//         pvc.push(data[i].pvCurrent);
//       }
//     }
//     let name = "PV Current";
//     const newRow = {
//       data: pvc,
//       type: "line",
//       stack: "RMU Parameter graph3",
//       name: name,
//       smooth: true,
//       symbolSize: 6,
//       lineStyle: { width: 2 },
//     };
//     graphList.push(newRow);
//   }

//   if (dataObject.motorRMSCurrent) {
//     const mrms = [];
//     for (let i = 0; i < data.length; i++) {
//       if (data[i].motorRMSCurrent === null) {
//         mrms.push("0.00");
//       } else {
//         mrms.push(data[i].motorRMSCurrent);
//       }
//     }
//     let name = "Motor RMS Current";
//     const newRow = {
//       data: mrms,
//       type: "line",
//       stack: "RMU Parameter graph4",
//       name: name,
//       smooth: true,
//       symbolSize: 6,
//       lineStyle: { width: 2 },
//     };
//     graphList.push(newRow);
//   }
//   if (dataObject.motorFrequency) {
//     const mf = [];
//     for (let i = 0; i < data.length; i++) {
//       if (data[i].motorFrequency === null) {
//         mf.push("0.00");
//       } else {
//         mf.push(data[i].motorFrequency);
//       }
//     }
//     let name = "Motor Frequency";
//     const newRow = {
//       data: mf,
//       type: "line",
//       stack: "RMU Parameter graph5",
//       name: name,
//       smooth: true,
//       symbolSize: 6,
//       lineStyle: { width: 2 },
//     };
//     graphList.push(newRow);
//   }
//   if (dataObject.motorVoltageRMS) {
//     let mvrms = [];
//     for (let i = 0; i < data.length; i++) {
//       if (data[i].motorVoltageRMS === null) {
//         mvrms.push("0.00");
//       } else {
//         mvrms.push(data[i].motorVoltageRMS);
//       }
//     }
//     let name = "Motor Voltage";
//     const newRow = {
//       data: mvrms,
//       type: "line",
//       stack: "RMU Parameter graph6",
//       name: name,
//       smooth: true,
//       symbolSize: 6,
//       lineStyle: { width: 2 },
//     };
//     graphList.push(newRow);
//   }

//   if (dataObject.motorEnergyinkWH) {
//     let mekwk = [];
//     for (let i = 0; i < data.length; i++) {
//       if (data[i].motorEnergyinkWH === null) {
//         mekwk.push("0.00");
//       } else {
//         mekwk.push(data[i].motorEnergyinkWH);
//       }
//     }
//     let name = "Motor Energy";
//     const newRow = {
//       data: mekwk,
//       type: "line",
//       stack: "RMU Parameter graph7",
//       name: name,
//       smooth: true,
//       symbolSize: 6,
//       lineStyle: { width: 2 },
//     };
//     graphList.push(newRow);
//   }
//   if (dataObject.deviceTemperature) {
//     let devicetemp = [];
//     for (let i = 0; i < data.length; i++) {
//       if (data[i].deviceTemperature === null) {
//         devicetemp.push("0.00");
//       } else {
//         devicetemp.push(data[i].deviceTemperature);
//       }
//     }
//     let name = "Device Temp";
//     const newRow = {
//       data: devicetemp,
//       type: "line",
//       stack: "RMU Parameter graph8",
//       name: name,
//       smooth: true,
//       symbolSize: 6,
//       lineStyle: { width: 2 },
//     };
//     graphList.push(newRow);
//   }
//   if (dataObject.motorPowerFactor) {
//     let mpf = [];
//     for (let i = 0; i < data.length; i++) {
//       if (data[i].motorPowerFactor === null) {
//         mpf.push("0.00");
//       } else {
//         mpf.push(data[i].motorPowerFactor);
//       }
//     }
//     let name = "Motor Power Factor";
//     const newRow = {
//       data: mpf,
//       type: "line",
//       stack: "RMU Parameter graph9",
//       name: name,
//       smooth: true,
//       symbolSize: 6,
//       lineStyle: { width: 2 },
//     };
//     graphList.push(newRow);
//   }
//   if (dataObject.flowRate) {
//     let fr = [];
//     for (let i = 0; i < data.length; i++) {
//       if (data[i].flowRate === null) {
//         fr.push("0.00");
//       } else {
//         fr.push(data[i].flowRate);
//       }
//     }
//     let name = "Flow Rate";
//     const newRow = {
//       data: fr,
//       type: "line",
//       stack: "RMU Parameter graph11",
//       name: name,
//       smooth: true,
//       symbolSize: 6,
//       lineStyle: { width: 2 },
//     };
//     graphList.push(newRow);
//   }
//   let xaxis = [];
//   for (let i = 0; i < data.length; i++) {
//     xaxis.push(data[i].dateTime.split("T")[0]);
//   }
//   return { gData: graphList, xAxisList: xaxis };
// };
