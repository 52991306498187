// import history from "history.js";
import axios from "axios";
import { get, post, getWithParams } from "../baseApi";
import { error, success, warning } from "../../../views/utilities/Toast/Toast";
import { authHeaderWithToken, authHeaderWithOutToken } from "../AxiosHeader";
import UrlConfig from "./UrlConfig";

export const ADD_RMU_PARAMS_LOADING = "ADD_RMU_PARAMS_LOADING";
export const ADD_RMU_PARAMS_SUCCESS = "ADD_RMU_PARAMS_SUCCESS";
export const ADD_RMU_PARAMS_ERROR = "ADD_RMU_PARAMS_ERROR";

export const DEVICE_REG_LOADING = "DEVICE_REG_LOADING";
export const DEVICE_REG_SUCCESS = "DEVICE_REG_SUCCESS";
export const DEVICE_REG_ERROR = "DEVICE_REG_ERROR  ";

export const GET_DEVICE_DETAILS_LOADING = "GET_DEVICE_DETAILS_LOADING";
export const GET_DEVICE_DETAILS_SUCCESS = "GET_DEVICE_DETAILS_SUCCESS";
export const GET_DEVICE_DETAILS_ERROR = "GET_DEVICE_DETAILS_ERROR  ";

export const PARAM_MSG_REMOVE = "PARAM_MSG_REMOVE";
export const CLEAR_RMU_PARAM = "CLEAR_RMU_PARAM";

export const INST_HIST_LOADING = "INST_HIST_LOADING";
export const INST_HIST_SUCCESS = "INST_HIST_SUCCESS";

export const GET_INST_HIST_LOADING = "GET_INST_HIST_LOADING";
export const GET_INST_HIST_SUCCESS = "GET_INST_HIST_SUCCESS";
export const GET_INST_HIST_ERROR = "GET_INST_HIST_ERROR";

export const CLEAR_INST_HIST = "CLEAR_INST_HIST";

export const addRMUParamters = (payload, isNew) => async (dispatch) => {
  let url = UrlConfig.addParametersURL();
  // url = `${url}${id}`;

  dispatch({
    type: ADD_RMU_PARAMS_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    const dynamicMessage = res && res.data;
    if (dynamicMessage === "Device Parameter already exist") {
      warning(dynamicMessage);
    } else {
      success(dynamicMessage);
    }

    dispatch({
      type: ADD_RMU_PARAMS_SUCCESS,
      data: res.data,
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while adding RMU parameters");
    }
    dispatch({
      type: ADD_RMU_PARAMS_ERROR,
      //payload: res.isAxiosError
    });
    return false;
  }
  return true;
};

export const ClearParamAddMessage = () => async (dispatch) => {
  dispatch({
    type: PARAM_MSG_REMOVE,
  });
};

export const DeviceRegistration = (payload) => async (dispatch) => {
  let url = UrlConfig.registrationURL();
  // url = `${url}${id}`;
  dispatch({
    type: DEVICE_REG_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    success("Device registered successfully");

    dispatch({
      type: DEVICE_REG_SUCCESS,
      data: res.data,
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while register device");
    }
    dispatch({
      type: DEVICE_REG_ERROR,
      //payload: res.isAxiosError
    });
    return false;
  }
  return true;
};

export const clearGetDeviceDetails = () => async (dispatch) => {
  dispatch({
    type: GET_DEVICE_DETAILS_SUCCESS,
    data: null,
  });
};

export const getDeviceDetails = (imei) => async (dispatch) => {
  // let d="21221212121212121"
  let url = UrlConfig.getDeviceDetials();
  url = `${url}/${imei}`;
  dispatch({
    type: GET_DEVICE_DETAILS_LOADING,
  });

  let header = authHeaderWithToken();
  //let params = {reportid: id };
  let res = await get(url, header);
  if (res && res.status === 200) {
    return dispatch({
      type: GET_DEVICE_DETAILS_SUCCESS,
      data: res.data,
    });
  } else if (res && res.status === 204) {
    warning("Content not available.");
    return dispatch({
      type: GET_DEVICE_DETAILS_SUCCESS,
      data: null,
    });
  } else {
    // if (res && res.isAxiosError) {
    //   if (res.response && res.response.data && res.response.data.message) {
    //     error(res.response.data.message);
    //   } else {
    //error("Error while fetching report...");
    return dispatch({
      type: GET_DEVICE_DETAILS_ERROR,
    });
  }

  // }
};

export const clearRMUParam = () => (dispatch) => {
  return dispatch({
    type: CLEAR_RMU_PARAM,
  });
};

export const postInstallationHistory = (payload) => async (dispatch) => {
  let url = UrlConfig.getInstHistURL();

  dispatch({
    type: INST_HIST_LOADING,
  });

  let header = authHeaderWithToken();
  let res = await post(url, payload, header);
  if (res && res.status === 200) {
    success("Submitted successfully...");

    dispatch({
      type: INST_HIST_SUCCESS,
    });
    return true;
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while Submitting...");
    }
    return false;
  }
  return true;
};

export const getInstallationHist = (imei) => async (dispatch) => {
  let url = UrlConfig.getInstHistrURL();
  url = `${url}${imei}`;

  dispatch({
    type: GET_INST_HIST_LOADING,
  });

  let header = authHeaderWithToken();
  //let params = {reportid: id };

  let res = await get(url, header);
  if (res && res.status === 200) {
    //res && res.data
    return dispatch({
      type: GET_INST_HIST_SUCCESS,
      data: res.data, //res.data
    });
  } else if (res && res.status === 204) {
    warning("Content not available.");
    return dispatch({
      type: GET_INST_HIST_SUCCESS,
      data: res.data,
    });
  } else if (res && res.isAxiosError) {
    if (res.response && res.response.data && res.response.data.message) {
      error(res.response.data.message);
    } else {
      error("Error while fetching report...");
    }
    return dispatch({
      type: GET_INST_HIST_ERROR,
      //payload: res.isAxiosError
    });
  }
};

export const clearInstHist = () => async (dispatch) => {
  dispatch({
    type: CLEAR_INST_HIST,
  });
};

// export const getRMULiveData = (id) => async (dispatch) => {
//   let url = UrlConfig.getLiveDataURL();
//   url = `${url}${'/'+id}`;

//   dispatch({

//     type: RMU_LIVE_DATA_LOADING,
//   });

//   let header = authHeaderWithToken();
//   //let params = {reportid: id };

//   let res = await get(url, header);
//   if (res && res.status === 200) {
//     //res && res.data

//     return dispatch({
//       type: RMU_LIVE_DATA_SUCCESS,
//       data: res.data, //res.data
//     });
//   }
//   else if(res && res.status === 204){
//     warning('Content not available.')
//     return dispatch({
//       type: RMU_LIVE_DATA_SUCCESS,
//       data:res.data,
//     });
//   }
//   else if (res && res.isAxiosError) {
//     if (res.response && res.response.data && res.response.data.message) {
//       error(res.response.data.message);
//     } else {
//       error("Error while fetching report...");
//     }
//     return dispatch({
//       type: RMU_LIVE_DATA_ERROR,
//       //payload: res.isAxiosError
//     });
//   }
// }

// export const clearStoredReport = () => dispatch =>{
//   return dispatch({
//     type: NCR_CLEAR_REPORT
//   });
// }
