import React from "react";

const formsRoutes = [
  {
    path: "/forms/RMULiveData",
    component: React.lazy(() => import("./RMULiveData/RMULiveData")),
  },
  {
    path: "/forms/Registration",
    component: React.lazy(() => import("./Registration/FarmerRegistration")),
  },
  {
    path: "/forms/RMUAlertView",
    component: React.lazy(() => import("./RMUAlertView/RmuAlertView")),
  },
  {
    path: "/forms/AddRMUParameters",
    component: React.lazy(() => import("./AddRMUParameters/RMUParameters")),
  },
  {
    path: "/forms/RMUChannelHistory",
    component: React.lazy(() => import("./RMUChannelHistory/ChannelHistory")),
  },
  {
    path: "/forms/RMUMultiGraph",
    component: React.lazy(() => import("./RMUMultiGraph/MultiGraph")),
  },
  {
    path: "/forms/RMUCorbonSavings",
    component: React.lazy(() => import("./RMUCorbonSavings/CorbonSavings")),
  },
  {
    path: "/forms/installationHist",
    component: React.lazy(() => import("./Registration/InstallationHist")),
  },
  {
    path: "/forms/postinstallationHist",
    component: React.lazy(() => import("./Registration/PostInstallationHist")),
  },
  {
    path: "/forms/registeragency",
    component: React.lazy(() => import("./Registration/RegisterAgency")),
  },
  // {
  //   path: "/forms/SearchTable",
  //   component: React.lazy(() => import("./SearchTable")),
  // },
];

export default formsRoutes;
