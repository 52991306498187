import {
  DASHBOARD_LOADING,
  DASHBOARD_SUCCESS,
  DASHBOARD_ERROR,
  RMU_DASHBOARD_LOADING,
  RMU_DASHBOARD_SUCCESS,
  RMU_DASHBOARD_ERROR,
  LOCATIONS_LOADING,
  LOCATIONS_SUCCESS,
  LOCATIONS_ERROR,
} from "../../actions/Dashboard/Dashboard";

const initialState = {
  dashboardLoading: false,
  dashBoardData: [],
  locationsDataList: [],
};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case RMU_DASHBOARD_LOADING: {
      return {
        ...state,
        dashboardRMULoading: true,
      };
    }
    case RMU_DASHBOARD_SUCCESS: {
      return {
        ...state,
        rmudata: action.data,
        dashboardRMULoading: false,
      };
    }
    case RMU_DASHBOARD_ERROR: {
      return {
        ...state,
        dashboardRMULoading: false,
      };
    }

    case LOCATIONS_LOADING: {
      return {
        ...state,
        dashboardRMULoading: true,
      };
    }
    case LOCATIONS_SUCCESS: {
      return {
        ...state,
        locationsDataList: action.data,
        dashboardRMULoading: false,
      };
    }
    case LOCATIONS_ERROR: {
      return {
        ...state,
        dashboardRMULoading: false,
      };
    }

    case DASHBOARD_LOADING: {
      return {
        ...state,
        dashboardLoading: true,
      };
    }
    case DASHBOARD_SUCCESS: {
      return {
        ...state,
        dashBoardData: action.data,
        dashboardLoading: false,
      };
    }
    case DASHBOARD_ERROR: {
      return {
        ...state,
        dashBoardData: null,
        dashboardLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
